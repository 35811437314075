.App {
  text-align: center;
}

.App-logo {
  /* height: 5vmin; */
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear; 
  }
} */
.div-link {
  padding: 5px;
}

.login-btn {
    color: white;
    background-color: #663399;
    border-radius: 5px;
    height: 28px;
    width: 80px;
    font-size:  14px;
    font-weight: bold;
}

.login-btn:disabled {
  color: #aaa;
  background-color: #3d1e5c;
  /* border-radius: 5px;
  height: 28px;
  width: 80px;
  font-size:  14px;
  font-weight: bold; */
}
.login-btn:hover {
  cursor: pointer;
}
.login-btn:disabled:hover {
  cursor: default;
}
.logout-btn {
    color: white;
    background-color: #cc0000;
    height: 28px;
    width: 80px;
    font-size:  14px;
    font-weight: bold;
}

.App-header {
  background-color: #eeeeee;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: 15px;
  color: black;
}

table td {
  padding: 5px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 70%; /* Could be more or less, depending on screen size */
}
.btn{
  cursor: pointer;
}
