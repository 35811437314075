.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mailbox {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #4CAF50;
}
select {
    font-size: medium;
    padding: 3px;
    /* color: red; */
}