.mail-table {
    border-collapse: collapse;
    width: 95%;
}

.no-messages {
    padding: 60px;
    border: 1px solid #4CAF50;
}

table td {
    padding: 5px;
}

.mail-folder {
    border-radius: 5px;
    width: 75px;
    font-size: small;
    padding: 5px;
}
  
.mail-current-folder {
    border-radius: 5px;
    width: 75px;
    font-size: small;
    padding: 5px;
    color: white;
    background-color: #663399;
}

.mail-folder:hover {
    cursor: pointer;
    /* color: white; */
    background-color: #6fd873;
}
