textarea {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: block
}
.compose-modal{
    white-space: pre-line;
    text-align: left;
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /*Black w/ opacity*/
}
.compose-modal-content {
    background-color: #fefefe;
    /* border: 1px solid #888; */
    border: 2px solid #4CAF50;
    border-radius: 10px;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 30px;
    width: 500px; /* Could be more or less, depending on screen size */
}
.compose-modal-content input{
    size: 50;
    width: 450px;
    font-size: medium;
}
.compose-modal-content datalist{
    font-size: smaller;
}
.compose-modal-close {
    border-radius: 75px;
    width: 20px;
    padding: 8px;
    text-align: center;
    cursor: pointer;
    background-color:  #280f41;
}
/* 
.compose-modal-close:hover {
    cursor: pointer;
    float: right;
    background-color:  #6fd873;
    border-radius: 20px;
} */

.compose-modal-header {
    border-radius: 5px;
    text-align: right;
    font-weight: bolder;
    font-size: medium;
    /* padding: 10px; */
    color: white;
    background-color:  #663399;
    /* width: 5%; */
}  
.send-button{
    background-color: #663399;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-size: small;
    padding: 5px;
    width: 65px;
}
.send-button:disabled{
    background-color: #333333;
}

.compose-error {
    display: none; /* Hidden by default */
    color: red;
    padding: 5px;
}
