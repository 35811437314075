.mail-msg-modal{
    /* white-space: pre-line; */
    /* justify-content: left; */
    text-align: left;
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /*Black w/ opacity*/
}

.mail-msg-modal-content {
    border-radius: 15px;
    font-size:small;
    word-wrap: break-word;
    background-color: #fefefe;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 2px solid #4CAF50;
    width: 75%; /* Could be more or less, depending on screen size */
}
.mail-msg-modal-content div {
    padding: 5px;
}

.msg-modal-source {
    display: none; /* Hidden by default */
    padding: 5px;
}

.mail-msg-modal-subject {
    border-radius: 5px;
    text-align: right;
    font-weight: bolder;
    font-size: medium;
    color: white;
    background-color:  #663399;
}
.mail-msg-modal-close {
    border-radius: 75px;
    width: 20px;
    padding: 8px;
    text-align: center;
    cursor: pointer;
    background-color:  #280f41;
}
