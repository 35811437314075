
.password-strength-meter-progress {
  /* -webkit-appearance: none; */
  appearance: none;
  width: 160px;
  height: 12px;
}
.password-strength-meter {
  background-color: #aaa;
  border-radius: 5px;
  width: 160px;
}

.password-strength-meter-label {
  font-size: 14px;
}
.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
}

.strength-Weak::-webkit-progress-value {
  background-color: #F25F5C;
  border-radius: 5px;
}
.strength-Weak{
  background-color: #F25F5C;
  border-radius: 5px;
  width: 40px;
}
/* .strength-Fair { */
.strength-Fair::-webkit-progress-value {
  background-color: #FFE066;
  border-radius: 5px;
}
.strength-Fair {
  background-color: #FFE066;
  border-radius: 5px;
  width: 80px;
}
/* .strength-Good { */
.strength-Good::-webkit-progress-value {
  background-color: #247BA0;
  border-radius: 5px;
}
.strength-Good {
  background-color: #247BA0;
  border-radius: 5px;
  width: 120px;
}
/* .strength-Strong { */
.strength-Strong::-webkit-progress-value {
  background-color: #70C1B3;
  border-radius: 5px;
}
.strength-Strong {
  background-color: #70C1B3;
  border-radius: 5px;
  width: 160px;
}