.check-failed {
  color: red;
  font-size: 16px;
  padding: 1px;
  width: 20px;
}

.check-passed {
  color: green;
  font-size: 14px;
  width: 20px;
}

.check-table  {
  width: 460px;
}

.check-table-td {
  vertical-align: top;
  text-align: left;
  padding: 1px;
  width: 22px;
}

.check-table td {
  vertical-align: top;
  text-align: left;
  padding: 1px;
}

.signup-message{
  width: 400px;
  text-align: left;
  padding: 20px;
}

.warning-message{
  padding: 5px;
  color: red;
}