.contacts-menu {
  text-align: left;
  padding: 10px;
  background-color: #eee;
  border-radius: 10px;
  margin-right: 30px;
  margin-bottom: 30px;
  border: 2px solid #4CAF50;
}
.contacts {
  text-align: left;
  width: 100%;
  overflow-y: scroll;
  min-height: 120px;
  height: calc(100vh - 210px);
}
.contacts::-webkit-scrollbar {
  width: 12px;
}
.contacts::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}
.contacts::-webkit-scrollbar-thumb {
  background: #639;
  border-radius: 5px;
}
.contacts::-webkit-scrollbar-thumb:hover {
  background: #4CAF50;
}
.contacts td {
  font-size: small;
  text-align: left;
  vertical-align: top;
  border-top: 1pt solid black;
  border-bottom: 1pt solid black;
}
.contacts table {
  border-collapse: collapse;
  width: 100%;
}
.contacts thead th {
  position: sticky;
  top: 0px;
  background: #eee;
  border-bottom: 1pt solid black;
}
.contacts tr {
  text-align: left;
  background-color: #fff; 
}
.contacts tr:hover {
  background-color: #6fd873;
}
.no-messages {
  padding: 60px;
  border: 1px solid #4CAF50;
}
.warning-message{
  padding: 5px;
  color: red;
}
