.contacts-upload {
    /* padding-top: 150px; */
    display: inline;
    position: fixed;
    z-index: auto; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* text-align: center; */
    justify-content: center;
    /* align-items: center; */
    /* vertical-align: middle; */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /*Black w/ opacity*/  
}
.contacts-upload-modal-content {
    background-color: #fefefe;
    /* border: 1px solid #888; */
    border: 2px solid #4CAF50;
    /* padding: 10px; */
    border-radius: 10px;
    margin: 5% auto; /* 15% from the top and centered */
    /* padding: 30px; */
    width: 600px; /* Could be more or less, depending on screen size */
}
.contacts-upload-modal-content h2 {
    margin: 5% auto; /* 15% from the top and centered */
}
.contacts-upload-modal-content table {
    width: 100%;
}
.contacts-upload-modal-content td {
    vertical-align: top;
}
.contacts-upload-modal-content tr#borders {
    border: 2px solid #4CAF50;
    border-radius: 10px;
    margin: 5px
}
.contacts-upload-modal-content div#no-padding {
    margin: 0px;
}
.contacts-upload-modal-content div {
    font-size: medium;
    text-align: left;
    word-wrap: break-word;
    background-color: #fefefe;
    margin: 5% auto; /* 15% from the top and centered */
    border: #fefefe;
    width: 90%; /* Could be more or less, depending on screen size */
}
.contacts-upload-modal-content #modal-close {
    border-radius: 75px;
    width: 20px;
    padding: 8px;
    text-align: center;
    font-weight: bolder;
    font-size: medium;
    color:#fefefe;
    cursor: pointer;
    background-color:  #663399;
}
.contacts-upload-modal-content #compose-error {
    display: none; /* Hidden by default */
    color: red;
    padding: 5px;
}
.contacts-upload-modal-content #upload-button{
    background-color: #663399;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-size: small;
    padding: 5px;
    cursor: pointer;
    width: 65px;
}