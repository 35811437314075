.no-messages {
    padding: 60px;
    border: 1px solid #4CAF50;
}

.folder-note {
    position: sticky;
    top: 0px;
    background-color: #fff;
    opacity: 1;
    font-size: small;
    text-align: left;
    padding: 5px
}
.mailbox-items {
    overflow-y: scroll;
    scrollbar-width: 10px;
    min-height: 120px;
    height: calc( 100vh - 250px );
}
.mailbox-items::-webkit-scrollbar {
    width: 12px;
}
.mailbox-items::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}
.mailbox-items::-webkit-scrollbar-thumb {
    background: #639;
    border-radius: 5px;
}
.mailbox-items::-webkit-scrollbar-thumb:hover {
    background: #4CAF50;
}
.moveTo {
    text-transform: capitalize;
}