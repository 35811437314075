table {
    border-collapse: collapse;
}
.tr-mail {
    border-top: 1pt solid black;
    border-bottom: 1pt solid black;
}

.tr-mail:hover {
    background-color: #6fd873;
    /* border-top: 1pt solid #6fd873;
    border-bottom: 1pt solid #6fd873; */
}

.td-mail-unread {
    font-size: small;
    font-weight: bold;
    color: #000000;
    /* border-spacing:0;
    border-collapse: collapse; */
    /* border-top: 1px solid black;
    border-bottom: 1px solid black; */
}

.td-mail-read {
    font-size: small;
    color: grey;
    border-spacing:0;
    border-collapse: collapse;
    /* border-top: 1px solid black;
    border-bottom: 1px solid black; */
}

.td-mail-read:hover {
    cursor: pointer;
}
.td-mail-unread:hover {
    cursor: pointer;
}