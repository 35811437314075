.email-address {
  width: 100%;
  
}
.email-address input {
  margin-bottom: 5px;
  
}
.email-address span#delete-item {
  margin-bottom: 5px;
  border-radius: 7px;
  width: 40px;
  padding: 7px;
  text-align: center;
  font-weight: bolder;
  font-size: small;
  color: #fff;
  cursor: pointer;
  background-color:  #4CAF50;
}
