
.video-player {
  display: none;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.8); /*Black w/ opacity*/
}

.movie-detail-bg {
  padding: 15px;
  /*
  align-content: center;
  */
  display: none;
  position: fixed; /* Stay in place */
  z-index: 0.5; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-image: none;
}

.movie-detail {
  padding: 15px;
  /*
  align-content: center;
  */
  display: none;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.6); /*Black w/ opacity*/
}
.movie-detail td {
  padding: 15px;
  vertical-align: top;
  align-content: left;
  text-align: left;
  /*
  align-content: center;
  display: none;
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.8);
  */
}

.movie-frame {
  padding: 15px;
  /* align-content: center; */
  background-color: black;
  color: white;
}

.movie-frame img:hover{
  cursor: pointer;
}

.movie-frame span:hover{
  cursor: pointer;
}

.movie-frame span {
  border: 1px solid #280f41;
  display: inline-block;
  /* display: table-cell; */
  padding: 15px;
  height: 300px;
  width: 180px;
  align-content: center;
  vertical-align: middle;
  background-color: black;
  color: white;
}

.video-player span {
  /* width: 75%; */
  padding: 15px;
  /* align-content: center; */
  color: white;
}

.video-player #player-frame {
  /* width: 80%; */
  align-content: center;
  color: white;
  padding: 15px;
}

.video-player video {
  min-height: 80%;
  min-width: 80%;
  position: inline-block;
  top: 10%;
  left: 10%;
  /* transform: translate(-40%, -40%); */
}

.media-button:hover{
 background-color: #6fd873
}
.media-button {
  /*
  position: fixed;
  right: 30%;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  */
  opacity: 1;
  border-spacing: 10px;
  border-radius: 75px;
  width: 40px;
  height: 40px;
  padding: 10px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  color:  white;
  background-color:  #663399;
}
.media-button-wrapper {
  /*
  position: fixed;
  right: 30%;
  vertical-align: middle;
  background-color:  #663399;
  */
  opacity: 1;
  display: table-cell;
  position: relative;
  border-spacing: 10px;
  border-radius: 75px;
  width: 40px;
  height: 40px;
  padding: 10px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  color:  white;
}
